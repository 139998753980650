import { httpPost } from './https';
import { LOGGER_APP_DOMAIN } from '../constants';

export const httpPostAmplitude = ({ source, id, title, params, channel, allySlug }) => {
  const body = {
    id,
    title,
    params: {
      executedAt: (new Date()).toString(),
      ...params
    },
    properties: {
      channel,
      allySlug
    }
  };

  return httpPost(LOGGER_APP_DOMAIN, `/api/events/${source}`, JSON.stringify(body));
};
