import { createUUID } from './helpers';
import { AMPLITUDE_EVENT, COUNTRY, ECOMMERCE_ROUTES, HOME_BANNER_PROD_URL, HOME_BANNER_STAGING_URL, PAGE_TYPES, PDP_WIDGET_PROD_URL, PDP_WIDGET_STAGING_URL, TEST_ENVIRONMENT_ENABLED } from './constants';
import { widgetTrackerAmplitude } from './services/tracker';

(function () {
  /**
     *
     * IMPORTS
     *
     */

  /**
     * Import widget bundles
     */
  function importAddiWidgetBundle (PAGE_CATEGORY) {
    if (PAGE_CATEGORY === PAGE_TYPES.home) {
      const hmBannerScriptElement = document.createElement('script');
      hmBannerScriptElement.type = 'module';
      hmBannerScriptElement.src = TEST_ENVIRONMENT_ENABLED ? HOME_BANNER_STAGING_URL : HOME_BANNER_PROD_URL;
      document.head.append(hmBannerScriptElement);
    }

    if (PAGE_CATEGORY === PAGE_TYPES.product) {
      const pdpWdgScriptElement = document.createElement('script');
      pdpWdgScriptElement.type = 'module';
      pdpWdgScriptElement.src = TEST_ENVIRONMENT_ENABLED ? PDP_WIDGET_STAGING_URL : PDP_WIDGET_PROD_URL;
      document.head.append(pdpWdgScriptElement);
    }
  }

  /**
     *
     * DATA INITIALIZATION
     *
     */

  const onScriptInitTime = new Date();
  const UUId = createUUID();

  const GENERIC_SELECTORS = [
    '.js-product-variants'
  ];
  const ADDI_SCRIPT_TAG = document.querySelector('script[data-id][data-name="nuvemAddiWidget"]');
  const HTML_ELEMENT_REFERENCE_FROM_TAG = ADDI_SCRIPT_TAG.getAttribute('data-element-reference');
  const ALLY_SLUG = ADDI_SCRIPT_TAG.getAttribute('data-ally-slug');
  const WIDGET_BANNER_DATA = {
    showBanner: false,
    bannerId: '',
    bannerElementRef: ''
  };

  const WIDGET_CUSTOM_STYLES = {
    borderColor: ADDI_SCRIPT_TAG.getAttribute('widget-border-color'),
    borderRadius: ADDI_SCRIPT_TAG.getAttribute('widget-border-radius'),
    fontColor: ADDI_SCRIPT_TAG.getAttribute('widget-font-color'),
    fontFamily: ADDI_SCRIPT_TAG.getAttribute('widget-font-family'),
    fontSize: ADDI_SCRIPT_TAG.getAttribute('widget-font-size'),
    badgeBackgroundColor: ADDI_SCRIPT_TAG.getAttribute('widget-badge-background-color'),
    infoBackgroundColor: ADDI_SCRIPT_TAG.getAttribute('widget-info-background-color'),
    iconType: ADDI_SCRIPT_TAG.getAttribute('widget-addi-icon-type'),
    iconSize: ADDI_SCRIPT_TAG.getAttribute('widget-addi-icon-size'),
    margin: ADDI_SCRIPT_TAG.getAttribute('widget-margin')
  };

  const MODAL_CUSTOM_STYLES = {
    backgroundColor: ADDI_SCRIPT_TAG.getAttribute('modal-background-color'),
    fontColor: ADDI_SCRIPT_TAG.getAttribute('modal-font-color'),
    fontFamily: ADDI_SCRIPT_TAG.getAttribute('modal-font-family'),
    priceColor: ADDI_SCRIPT_TAG.getAttribute('modal-price-color'),
    badgeBackgroundColor: ADDI_SCRIPT_TAG.getAttribute('modal-badge-background-color'),
    badgeFontColor: ADDI_SCRIPT_TAG.getAttribute('modal-badge-font-color'),
    badgeBorderRadius: ADDI_SCRIPT_TAG.getAttribute('modal-badge-border-radius'),
    cardColor: ADDI_SCRIPT_TAG.getAttribute('modal-card-color'),
    buttonBorderColor: ADDI_SCRIPT_TAG.getAttribute('modal-button-border-color'),
    buttonBorderRadius: ADDI_SCRIPT_TAG.getAttribute('modal-button-border-radius'),
    buttonBackgroundColor: ADDI_SCRIPT_TAG.getAttribute('modal-button-background-color'),
    buttonFontColor: ADDI_SCRIPT_TAG.getAttribute('modal-button-font-color')
  };

  let htmlElementReference = null;
  let amplitudeTracker = null;

  /**
     * * * * * *
     * * * TRACKER
     * * * * * *
    */

  const startAmplitudeEvents = () => {
    amplitudeTracker = widgetTrackerAmplitude(UUId, ALLY_SLUG);
  };

  /**
     *
     * METHODS
     *
     */

  function getReferenceNode (reference) {
    if (reference) {
      htmlElementReference = document.querySelector(reference);
      return htmlElementReference;
    } else {
      for (let i = 0; i < GENERIC_SELECTORS.length; i++) {
        htmlElementReference = document.querySelector(GENERIC_SELECTORS[i]);
        if (htmlElementReference) return htmlElementReference;
      }

      return null;
    }
  }

  /**
     * This function will insert a newNode into the DOM just after the given referenceNode
     * @param {*} newNode
     * @param {*} referenceNode
     */

  function insertAfter (newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  function onPageLoaded () {
    const PAGE_TEMPLATE = window.LS ? window.LS.template : getPageTemplateFromUrl();

    importAddiWidgetBundle(PAGE_TEMPLATE);
    startAmplitudeEvents();

    if (PAGE_TEMPLATE === PAGE_TYPES.product) {
      const productData = window.LS || {};
      const productInfo = productData.variants && productData.variants.find(variant => variant.available);
      const productPrice = productInfo.price_number;

      renderAddiWidget(productPrice);
    }

    if (PAGE_TEMPLATE === PAGE_TYPES.home) {
      WIDGET_BANNER_DATA.showBanner = ADDI_SCRIPT_TAG.getAttribute('data-show-banner') === 'true';
      WIDGET_BANNER_DATA.bannerElementRef = ADDI_SCRIPT_TAG.getAttribute('data-banner-element-reference');
      WIDGET_BANNER_DATA.bannerId = ADDI_SCRIPT_TAG.getAttribute('data-banner-id');

      if (WIDGET_BANNER_DATA.showBanner) {
        renderAddiHomeBanner();
      }
    }
  }

  function onProductVariantSelected (ProductVariantData) {
    renderAddiWidget(ProductVariantData.price_number);
  }

  /**
     * Get location
     */

  function getPageTemplateFromUrl () {
    const URLpathName = window.location.pathname;

    if (URLpathName === ECOMMERCE_ROUTES.home) {
      return PAGE_TYPES.home;
    } else if (URLpathName.endsWith(ECOMMERCE_ROUTES.product) || URLpathName.includes(ECOMMERCE_ROUTES.product)) {
      return PAGE_TYPES.product;
    }

    return '';
  }

  function getCustomizableWidgetStyles () {
    const getStylesProperties = (Obj) => {
      const objKeys = Object.keys(Obj);
      const newObject = {};

      for (let i = 0; i < Object.keys(Obj).length; i++) {
        if (Obj[objKeys[i]]) {
          newObject[objKeys[i]] = Obj[objKeys[i]];
        }
      }

      return newObject;
    };

    return JSON.stringify({
      widget: getStylesProperties(WIDGET_CUSTOM_STYLES),
      modal: getStylesProperties(MODAL_CUSTOM_STYLES)
    });
  }

  function renderAddiWidget (price) {
    const elementReference = htmlElementReference || getReferenceNode(HTML_ELEMENT_REFERENCE_FROM_TAG);

    if (elementReference) {
      const currentWidget = document.getElementsByTagName('addi-product-widget');
      if (currentWidget[0]) {
        currentWidget[0].remove();
      }
      const addiWidget = document.createElement('addi-product-widget');

      addiWidget.setAttribute('ally-slug', ALLY_SLUG);
      addiWidget.setAttribute('price', price);
      addiWidget.setAttribute('country', COUNTRY);
      addiWidget.setAttribute('custom-widget-styles', getCustomizableWidgetStyles());

      addiWidget.addEventListener('onWidgetClickedEvent', () => {
        amplitudeTracker && amplitudeTracker(AMPLITUDE_EVENT.WIDGET_CLICKED);
      });

      insertAfter(addiWidget, elementReference);

      const onWdgInsertedTime = new Date();
      amplitudeTracker && amplitudeTracker(AMPLITUDE_EVENT.WIDGET_INSERTED, {
        onScriptInitTimeMs: onScriptInitTime.getTime(),
        onWdgInsertedTimeMs: onWdgInsertedTime.getTime(),
        onWdgInsertedDurationMs: onWdgInsertedTime.getTime() - onScriptInitTime.getTime()
      });

      // This is a temporary event
      setTimeout(() => {
        const widgetElement = document.querySelector('addi-product-widget');
        if (!widgetElement) {
          console.log('Widget was NOT created');
          amplitudeTracker && amplitudeTracker(AMPLITUDE_EVENT.WIDGET_NOT_INSERTED, {
            onScriptInitTimeMs: onScriptInitTime.getTime(),
            widgetInsertionFailedTimeMs: onWdgInsertedTime.getTime()
          });
        }

        if (!(widgetElement?.shadowRoot?.hasChildNodes())) {
          amplitudeTracker && amplitudeTracker(AMPLITUDE_EVENT.WIDGET_NOT_DISPLAYED, {
            onScriptInitTimeMs: onScriptInitTime.getTime(),
            widgetInsertionFailedTimeMs: onWdgInsertedTime.getTime()
          });
        }
      }, 7000);
    } else {
      console.log('No reference node was not found.');
    }
  }

  function renderAddiHomeBanner () {
    const elementReference = htmlElementReference || getReferenceNode(WIDGET_BANNER_DATA.bannerElementRef);

    if (elementReference) {
      const currentHomeBanner = document.getElementsByTagName('addi-home-banner');
      if (currentHomeBanner[0]) {
        currentHomeBanner[0].remove();
      }

      const addiHomeBanner = document.createElement('addi-home-banner');

      if (WIDGET_BANNER_DATA.bannerId) {
        addiHomeBanner.setAttribute('ally-slug', ALLY_SLUG);
        addiHomeBanner.setAttribute('banner-id', WIDGET_BANNER_DATA.bannerId);
      }

      insertAfter(addiHomeBanner, elementReference);

      setTimeout(() => {
        const bannerElement = document.querySelector('addi-home-banner');
        if (!bannerElement) {
          console.log('Banner was NOT created');
        }

        if (!(bannerElement?.shadowRoot?.hasChildNodes())) {
          console.log('Banner was NOT inserted');
        }
      }, 7000);
    } else {
      console.log('No reference node was not found for home banner.');
    }
  }

  /**
     * Event's listeners
     */

  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    onPageLoaded();
  } else {
    window.addEventListener('DOMContentLoaded', onPageLoaded);
  }

  if (window?.LS?.registerOnChangeVariant) { window?.LS?.registerOnChangeVariant(onProductVariantSelected); }
})();
