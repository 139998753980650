/**
 *
 * @returns return unique ID
 */

export const createUUID = () => {
  return (`${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`).replace(/[018]/g, (c) => {
    const num = +c;
    return (num ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> num / 4).toString(16);
  });
};
