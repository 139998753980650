export const httpPost = async (domain, path, body = {}, options = {}) => {
  const url = `${domain}${path}`;
  options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  };

  return await fetch(url, { ...options, body })
    .then(response => response.json())
    .catch((error) => error);
};
