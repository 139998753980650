import { AppSources, CHANNEL } from '../constants';
import { httpPostAmplitude } from './amplitude';

export const widgetTrackerAmplitude = (
  id,
  allySlug
) => {
  return (
    title = '',
    params = {}
  ) => httpPostAmplitude({
    id,
    title,
    params,
    source: AppSources.ALLY_INTEGRATION_PRODUCT,
    channel: CHANNEL,
    allySlug
  }).then();
};
