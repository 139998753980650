export const AppSources = {
  ALLY_INTEGRATION_PRODUCT: 'ALLY_INTEGRATION_PRODUCT'
};

export const AMPLITUDE_EVENT = {
  SCRIPT_STARTED: 'PDP_SCRIPT.SCRIPT_STARTED',
  WIDGET_INSERTED: 'PDP_SCRIPT.WIDGET_INSERTED',
  WIDGET_NOT_INSERTED: 'PDP_SCRIPT.WIDGET_NOT_INSERTED',
  WIDGET_RENDERED: 'PDP_SCRIPT.WIDGET_RENDERED',
  WIDGET_CLICKED: 'PDP_SCRIPT.WIDGET_CLICKED',
  WIDGET_NOT_DISPLAYED: 'PDP_SCRIPT.WIDGET_NOT_DISPLAYED',
  BANNER_NOT_INSERTED: 'PDP_SCRIPT.BANNER_NOT_INSERTED',
  BANNER_NOT_DISPLAYED: 'PDP_SCRIPT.BANNER_NOT_DISPLAYED'
};

export const CHANNEL = 'E_COMMERCE_NUVEMSHOP';

export const LOGGER_APP_DOMAIN = 'https://logger.addi.com';

export const PDP_WIDGET_STAGING_URL = 'https://cdn.addi-staging.com/product-details-widget/staging/v1/product-details-widget.esm.js';
export const PDP_WIDGET_PROD_URL = 'https://cdn.addi.com/product-details-widget/prod/v1/product-details-widget.esm.js';
export const HOME_BANNER_STAGING_URL = 'https://cdn.addi-staging.com/home-banner-widget/staging/v1/home-banner-widget.esm.js';
export const HOME_BANNER_PROD_URL = 'https://cdn.addi.com/home-banner-widget/prod/v1/home-banner-widget.esm.js';

export const ECOMMERCE_ROUTES = {
  product: '/productos/',
  home: '/'
};

export const PAGE_TYPES = {
  product: 'product',
  home: 'home'
};

export const COUNTRY = 'co';

export const TEST_ENVIRONMENT_ENABLED = false;

